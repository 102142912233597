export const SET_VERIFICATION_IMAGE = (state, data) => {
  state.userVerificationImage = data;
};

// export const SET_PRINT = (state, print) => {
//   state.print = print;
// };

// export const SET_PRINT_NOTIFICATION = (state, isSaved) => {
//   state.isPrintSaved = isSaved;
// };
